html,
body{
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Indie Flower', cursive;
  font-size: 1.1em;
  background-color: black;
  color: white;
}

.App {
  text-align: center;
}

.item-container {
  margin: 0.5em;
}
.App-title{
  text-align: right;
  margin-bottom: 0.2em;
  margin-right: 0.2em;
}
.add-item{
  text-align: right;
  text-decoration: none;
  
}
.add-item button {
  color: white;
}

.item-edit-icon{
  display: inline;
  margin-left: 5px;
  cursor: pointer;
}

.nav-item a{
  color: white;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link
{
  background-color: white;
  color: black;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link a
{
  color: black;
}

.modal-content{
    background-color: black;
}
.modal-footer .btn.btn-primary{
  background-color: white;
  color: black;
}

.modal-header .close{
    color:white;
}

.main-placeholder {
  margin-right: 0;
  min-height: calc(100vh - 50px);
}

.total-label{
  text-align: right;
  font-size: 1.5em;
  margin-right: 0.2em;
}
footer {
  position:sticky;
  bottom:0;
  width:100%;
  height:50px;   /* Height of the footer */
  background: white;
  color: black;
  padding-top: 0.5em;
}
 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
